import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.adjustHeight();
  }

  handleKeyPress(event) {
    if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
      event.preventDefault();
      this.element.requestSubmit();
    }
  }

  adjustHeight() {
    const input = this.inputTarget;
    input.style.height = "auto";
    input.style.height = `${input.scrollHeight}px`;
  }

  reset() {
    this.element.reset();
    this.adjustHeight();
  }
}
